<template>
  <div class="content">
    <div class="about">
      <div @click="checkout(0)">
        <p :class="tablecheckout == 0 ? 'abouthead' : ''">全部</p>
        <p v-show="tablecheckout == 0"></p>
      </div>
      <div @click="checkout(1)">
        <p :class="tablecheckout == 1 ? 'abouthead' : ''">获取</p>
        <p v-show="tablecheckout == 1"></p>
      </div>
      <div @click="checkout(2)">
        <p :class="tablecheckout == 2 ? 'abouthead' : ''">使用</p>
        <p v-show="tablecheckout == 2"></p>
      </div>
    </div>
    <div class="list" v-if="list.length > 0">
      <div class="listli" v-for="(item, index) in list" :key="index">
        <div>
          <p>{{ item.title }}</p>
          <p>{{ item.time }}</p>
        </div>
        <div  :style="Number(item.activity) < 0 ? 'color:#444444;' : ''">
          <span :style="Number(item.activity) < 0 ? 'color:#444444;' : ''">{{
            item.activity
          }}</span
          >活跃值
        </div>
      </div>
    </div>
    <p class="bottomlist" v-if="list.length > 0">无更多记录</p>
    <div class="kong" v-if="list.length == 0">
      <img
        src="https://ppyos.xijiuyou.com/210915/fish-2.jpg"
        alt=""
      />
      <p>您还未获得活跃值噢</p>
    </div>
  </div>
</template>
<script>
import home from "../api/home";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      tablecheckout: 0,
      page: 1,
      pageSize: 10,
      list: [],
      listlength: true,
    };
  },
  scrollPage() {
    this.page++;
    this.actdetail();
  },
  mounted() {
    this.actdetail();
    const That = this;
    window.addEventListener("scroll", () => {
      var ScrollTop, ClientHeight, ScrollHeight;
      ScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      ClientHeight = document.documentElement.clientHeight;
      ScrollHeight = document.documentElement.scrollHeight;
      if (ScrollHeight - ScrollTop - ClientHeight == 0 && That.listlength) {
        this.page++;
        That.actdetail();
      }
    });
  },
  methods: {
    actdetail() {
      const data = {
        type: this.tablecheckout,
        page: this.page,
        pageSize: this.pageSize,
      };
      home.actdetail(data).then((res) => {
        if (res.list.length != 10) {
          this.listlength = false;
        }
        this.list.push(...res.list);
      });
    },
    checkout(index) {
      this.listlength = true;
      this.tablecheckout = index;
      this.list = [];
      this.actdetail();
    },
  },
};
</script>
<style scoped>
.content {
  width: 100vw;
  min-height: 100vh;
}
.about {
  display: flex;
  justify-content: space-around;
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  background: #ffffff;
  border: 1px solid #eeeeee;
  padding-top: 10px;
}
.about div .abouthead:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff5675;
}
.about div p:nth-child(2) {
  width: 20px;
  height: 4px;
  background: #ff5675;
  border-radius: 2px;
  margin: 0 auto;
  margin-top: 5px;
}
.bottomlist {
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 30px;
}
.list{
  padding: 0 12px;
  box-sizing: border-box;
  background: #ffffff;
}
.listli {
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  align-items: center;
  border-bottom: 0.5px solid rgba(238, 238, 238, 1);
}
.listli div:nth-child(1) {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.listli div p:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.listli div:nth-child(2) {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5675;
}
.listli div:nth-child(2) span {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5675;
  font-size: 20px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ff5675;
  margin-right: 5px;
}
.kong {
  text-align: center;
}
.kong img {
  margin-top: 115px;
  width: 123px;
  height: 98px;
  margin-bottom: 24px;
}
.kong p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 21px;
}
</style>

